.App {
  /* display: flex;
  background: #fffefa;
  background-color: #fffefa; */
  font-family: "Poppins Regular";
  /* height: 100%; */
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:visited {
    text-decoration: none;
  }
}

button {
  cursor: pointer;
}
