@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.customization_popup_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 35vw; /* to make it more responsive */
  padding: 0rem 1.5rem 2.125rem 1.5rem;
  border-radius: 0.9375rem;
  background: #fff;
  box-shadow: 0px 0px 41px -1px rgba(97, 97, 97, 0.15);
  z-index: 1;
}

.close_popup_container {
  @include flex-row;
  justify-content: flex-end;
  margin-block: 1rem 3rem;
}

.badge_logo_bright {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 5;
}

.badge_logo_bright svg {
  width: 7.5rem;
  height: 7.5rem;
}

.popup_content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 1.25rem;
}

.combine-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.form_field_with_label {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 16px;
  margin: 0.2rem;

  &__container {
    display: flex;
    gap: 0.5rem;
  }

  &__text {
    text-align: left;
    color: #000;
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
    margin: 0.2rem 0rem;
  }
}

.popup_header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 75%;
  margin: auto;

  &--maintext {
    color: var(--Foundation-Black, #383838);
    font-family: "Poppins Semibold";
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }

  &--subtext {
    color: var(--Foundation-Neutrals-500, #706f6e);
    text-align: center;
    font-family: "Poppins Regular";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0.0125rem;
    margin: 0;
  }
}

.button_container {
  @include flex-center;
}

.button_container * {
  width: 35%;
  margin-inline: 12px;
}

.error_message_container {
  @include flex-center;
  gap: 0.1rem;
  &__text {
    color: #dc2626;
    font-family: "Poppins Regular";
    font-size: 0.7rem;
    margin-top: 0.2rem;
  }
}

.dropdown-timeline {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  background-color: $neutral100;
  padding-block: 12px;
  padding-inline: 1rem;
  color: $black;
  color: $neutral900;
  cursor: pointer;
}

.dropdown-timeline option[disabled]:first-child {
  color: #aaa;
}

.dropdown-timeline:focus {
  outline: none;
}

@media only screen and (max-width: 440px) {
  .customization_popup_container {
    width: 75vw;
    padding: 0rem 1rem 1.625rem 1rem;
  }

  .badge_logo_bright svg {
    width: 5rem;
    height: 5rem;
  }

  .popup_content {
    gap: 0.8rem;
  }

  .combine-fields {
    display: block;
  }

  .form_field_with_label {
    width: 100%;
    &__text {
      font-size: 1rem;
    }
  }

  .popup_header {
    width: 85%;

    &--maintext {
      font-size: 1.2rem;
    }

    &--subtext {
      font-size: 1rem;
    }
  }

  .close_popup_container {
    @include flex-row;
    justify-content: flex-end;
    margin-block: 0.5rem 1.2rem;
  }

  .button_container * {
    width: 50%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 980px) {
}
