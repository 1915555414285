@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffefa;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
  }

  &__filler {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    overflow: hidden;

    @media (max-width: 1024px) {
      width: 100%;
      justify-content: center;
    }

    &::after {
      right: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      position: absolute;
      width: 30%;
      height: 100%;
      opacity: 0.3;
      -webkit-filter: blur(150px);
      filter: blur(150px);
      background-image: linear-gradient(43deg, $yellow500 22%, #ff9d43 89%);
      z-index: 0;
    }

    &--image {
      height: 80%;
      width: auto;
      flex-shrink: 0;
      z-index: 1;
      margin-right: -9%;

      @media (max-width: 1024px) {
        justify-content: center;
        width: 90%;
        height: auto;
        margin-bottom: 1rem;
        margin-right: 0;
      }

      @media (min-width: 1024px) and (max-width: 1220px) {
        height: 60%;
        margin-right: -9%;
      }
    }
  }

  &__intro {
    @include flex-column;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    box-sizing: border-box;
    padding: 0rem 4rem;

    @media (max-width: 1024px) {
      width: 100%;
      align-items: center;
    }

    &--logo {
      margin: 1rem;
      width: 4.78294rem;
      height: 1.73881rem;
    }

    &--titles {
      @include flex-column;
      align-items: flex-start;
      text-align: center;
      gap: 0.3rem;

      @media (max-width: 1024px) {
        align-items: center;
      }
    }

    &--description {
      color: #000;
      font-family: "Poppins Regular";
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.375rem;
      letter-spacing: 0.0125rem;

      @media (max-width: 768px) {
        text-align: center;
        font-size: 1rem;
        margin: 0.5rem 0rem;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        text-align: center;
        font-size: 1.2rem;
        margin: 0.5rem 0rem;
        width: 80%;
      }
    }

    &--button {
      width: 40%;
      margin-top: 2.25rem;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 0;
        margin-bottom: 2rem;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
        margin-top: 0;
        margin-bottom: 2rem;
      }
    }
  }
}

.header1 {
  color: #383838;
  font-family: "Avigea";
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 100%;
}

.header2 {
  font-family: "Avigea";
  font-size: 3.5em;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: linear-gradient(
    195deg,
    #ff9d43 21.67%,
    #ffbd43 57.35%,
    #ffc943 91.62%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 440px) {
  .header1 {
    font-size: 2rem;
  }
  .header2 {
    font-size: 2rem;
  }
}
