.show-spin-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ffc107;
  border-radius: 50%;
  margin-inline-end: 10px;
  animation: spin 1s linear infinite;
  margin-top: 1px;
}

.hide-spin-loader {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
