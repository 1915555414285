@import "./variables.scss";

@mixin tablet {
  @media (min-width: 768px) and (max-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

/*Buttons*/
@mixin gradient-button(
  $padding-vertical: 0.625rem,
  $padding-horizontal: 1.6875rem
) {
  background: linear-gradient(85.47deg, #ffc943 28.39%, #ff9d43 96.17%);
  box-shadow: 0px 4px 12px rgba(255, 184, 67, 0.68);
  border-radius: 4rem;
  padding: $padding-vertical $padding-horizontal;
  border: none;
  font-family: "Poppins Regular";
  color: $neutral700;
  font-size: 1rem;
  // height: 2.625rem;

  @media (max-width: 440px) {
    font-size: 0.75rem;
    padding: 0.5rem;
    border-radius: 2rem;
  }

  @media (min-width: 441px) and (max-width: 1024px) {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }
}

@mixin plain-button(
  $padding-vertical: 0.625rem,
  $padding-horizontal: 1.6875rem,
  $background-color: white
) {
  background-color: $background-color;
  border-radius: 4rem;
  border: 1px solid $neutral700;
  font-family: "Poppins Regular";
  color: $neutral700;
  font-size: 1rem;
  padding: $padding-vertical $padding-horizontal;
}

/*Dashboard Mixin*/

@mixin dashboard-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fffefa;
  width: 100%;
}

/*Flex Mixins*/

@mixin flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-grow {
  display: flex;
  flex-grow: 1;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/*Desktop & Tablet*/
@mixin header {
  font-family: "Poppins Medium";
  font-size: 1.25rem;
  color: $yellow700;
  margin: 0;
}

@mixin subheader {
  font-family: "Poppins Medium";
  font-size: 2.5rem;
  color: $black;
  margin: 0;
}

@mixin body {
  font-family: "Poppins Light";
  // font-size: 1.125rem;
  font-size: 1rem;
}

/*Mobile*/
@mixin mobile-header {
  font-size: 3rem;
  line-height: 3.5rem;
}

@mixin mobile-subheader {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin mobile-body {
  font-size: 0.9375rem;
  line-height: 1.625rem;
}
