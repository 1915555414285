@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.liked-card-match {
  @include flex-column-center;
  justify-content: center;

  &__circle {
    position: relative;
  }
  &__circle svg {
    width: 55px;
    height: 55px;
    margin-inline: auto;
  }
}

.liked-card-match-stamp {
  margin-inline: auto;
  padding: 0.5rem;
  position: absolute;
  bottom: 12px;
  left: 4px;

  &__number {
    color: $black;
    font-family: "Poppins Bold";
    font-size: 1rem;
    margin: 0;
  }
}

.match {
  &__text {
    display: flex;
    flex-direction: column;
    margin-block: 0.25rem;

    &--row {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }
}

.text-normal {
  margin: 0px;
  color: $black;
  font-size: 0.75rem;
  font-family: "Poppins SemiBold";
}

.text-bold {
  margin: 0px;
  color: $black;
  font-size: 0.875rem;
  font-family: "Poppins Bold";
}

@media only screen and (max-width: 480px) {
  .liked-card-match {
    &__circle svg {
      width: 28px;
      height: 28px;
    }
  }

  .liked-card-match-stamp {
    bottom: 6px;
    left: -2px;

    &__number {
      font-size: 0.6rem;
    }
  }

  .text-normal {
    font-size: 0.5rem;
  }

  .text-bold {
    font-size: 0.575rem;
  }
}

@media only screen and (min-width: 441px) and (max-width: 980px) {
  .liked-card-match {
    &__circle svg {
      width: 45px;
      height: 45x;
    }
  }

  .liked-card-match-stamp {
    bottom: 15px;
    left: 2px;

    &__number {
      font-size: 0.85rem;
    }
  }

  .text-normal {
    font-size: 0.675rem;
  }

  .text-bold {
    font-size: 0.725rem;
  }
}
