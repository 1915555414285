// @import "./variables";

/*Breakpoints*/
$breakpoint-mobile: 767px;
$breakpoint-tablet: 1279px;
$breakpoint-desktop: 1280px;

/*Fonts*/

@font-face {
  font-family: "Poppins ExtraLight";
  src: url("../../assets/fonts/poppins/Poppins-ExtraLight.ttf") format(truetype);
}

@font-face {
  font-family: "Poppins Light";
  src: url("../../assets/fonts/poppins/Poppins-Light.ttf") format(truetype);
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../assets/fonts/poppins/Poppins-Regular.ttf") format(truetype);
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../../assets/fonts/poppins/Poppins-Medium.ttf") format(truetype);
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../assets/fonts/poppins/Poppins-Bold.ttf") format(truetype);
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../../assets/fonts/poppins/Poppins-SemiBold.ttf") format(truetype);
}

@font-face {
  font-family: "Avigea";
  src: url("../../assets/fonts/avigea/avigea.ttf");
}

/*Primary Colors*/
$yellow-50: #fffaec;
$yellow100: #ffeec5;
$yellow200: #ffe6a9;
$yellow300: #ffdb81;
$yellow400: #ffd469;
$yellow500: #ffc943;
$yellow600: #e8b73d;
$yellow700: #b58f30;
$yellow800: #8c6f25;
$yellow900: #6b541c;

$black: #383838;

/*Secondary Colors*/
$secondary-50: #f9f8f7;
$secondary100: #edeae5;
$secondary200: #e4e0d8;
$secondary300: #d7d2c6;
$secondary400: #d0c9bb;
$secondary500: #c4bcaa;
$secondary600: #b2ab9b;
$secondary700: #8b8579;
$secondary800: #6c675e;
$secondary900: #524f47;

/*Neutral Colors*/
$neutral-50: #fafafa;
$neutral100: #efefee;
$neutral200: #d5d5d3;
$neutral300: #b9b8b6;
$neutral400: #908f8e;
$neutral500: #706f6e;
$neutral600: #57544d;
$neutral700: #4f4c46;
$neutral800: #3e3c37;
$neutral900: #302e2a;

/*Accent Colors*/
$accent800: #5e89b2;
$newNeutral700: #8b8579;
