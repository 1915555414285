@import "../../styles/partials/mixins.scss";

.progress-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  &__bar {
    width: 2rem;
    height: 0.3rem;
    border-radius: 0.05rem;
    margin: 0.3rem 0.2rem;
    background: var(--foundation-secondary-200, #e4e0d8);

    &--selected {
      background: var(--foundation-secondary-800, #6c675e);
    }
  }
}
