@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.form_field_with_labels {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  &__text {
    text-align: left;
    color: #000;
    font-family: "Poppins Regular";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0125rem;
  }
}
