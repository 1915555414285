@import "../../styles/partials/variables.scss";
@import "../../styles/partials/mixins.scss";

.page404 {
  @include flex-column-center;
  gap: 1rem;
  text-align: center;
  margin-block: 4.19rem 0;
  margin-inline: auto;
  font-family: "Poppins Regular";

  &__header {
    @include header;
    font-size: 1rem;
  }

  &__subheader {
    @include subheader;
    font-size: 2.25rem;
  }

  &__description {
    @include body;
    width: 50%;
  }

  &__support {
    font-size: 18px;
    margin: 0;

    a {
      color: #2f80ed;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__filler {
    width: 40vw;
    height: auto;
  }
}

@media only screen and (max-width: 440px) {
  .page404 {
    &__subheader {
      font-size: 1.25rem;
    }

    &__description {
      width: 90%;
    }

    &__support {
      font-size: 1rem;
    }

    &__filler {
      width: 60vw;
    }
  }
}

@media only screen and (min-width: 441px) and (max-width: 980px) {
  .page404 {
    &__subheader {
      font-size: 1.75rem;
    }

    &__description {
      width: 70%;
    }

    &__filler {
      width: 60vw;
    }
  }
}
