.container {
  width: 100%;
}

.dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.dropdown-group {
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

label {
  display: block;
  margin: 0.5rem 0rem;
  font-size: 14px;
}

.dropdown {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"><path d="M7 10l5 5 5-5z" /></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  cursor: pointer;
}

.dropdown option[disabled]:first-child {
  color: #aaa;
}
