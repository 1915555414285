@import "../../styles/partials/mixins.scss";
.email-input-container {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }

  input {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    border: 3px solid white;

    @media (max-width: 768px) {
      width: 90%;
    }

    &:focus {
      outline: none;
      border: 3px solid $yellow500;
      box-shadow: 0 0 0.5rem #ffc943;
    }
  }

  &.invalid {
    input {
      border-color: #dc3545;
    }

    .error-message {
      color: #dc3545;
      margin-top: 8px;
      font-size: 14px;
    }
  }

  .disclaimer {
    color: black;
    margin-top: 10px;
    font-size: 10px;
    font-style: bold;
  }
}
